import React, { useEffect, useState, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';

import { getSinglarOrPluralWord } from '../../utilities/helpers';

import { searchEvents } from '../../utilities/api';

import Button from 'react-bootstrap/Button';

import { SearchList } from '../SearchList';
import { PageLoadingContainer } from '../PageLoadingContainer'

// page
export default function SearchWrapper({ query }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    // api is called with limited results
    const [results, setResults] = useState()

    useEffect(() => {
        showLoading();
        let data = {
            data: query
        };
        searchEvents(data)
            .then(res => {
                setResults(res.data)
                hideLoading();
            })
            .catch(err => {
                console.error(err)
                hideLoading();
            })
    }, [query])

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <h1 className="fs-md mb-5">We found {results?.length} {results?.length > 0 ? getSinglarOrPluralWord(results?.length, 'result') : 'results'} for "{query}"</h1>
                    <SearchList queryResults={results} />
                    <Button variant="outline-light" className="mt-4">
                        Load more
                    </Button>
                </>
            )}

        </>
    );
}
