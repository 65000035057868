import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { getTimezoneDate } from '../../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';

import { LoadingContainer } from '../../../LoadingContainer';

import './countdown.scss';

export default function Countdown({ reloadQuickPicks, scheduledTime, timezone, showHeader = true }) {

    const [days, setDays] = useState()

    const [hours, setHours] = useState()

    const [minutes, setMinutes] = useState()

    const [seconds, setSeconds] = useState()

    const padWithZeros = num => {
        if (num < 10) {
            return `0${num}`
        } else {
            return num
        }
    }

    const startTimer = setInterval(() => {
        const now = getTimezoneDate(moment().toISOString(), timezone)
        console.log('current time', moment().toDate(), now.format(), now.toDate());
        const ticketDate = getTimezoneDate(scheduledTime, timezone)
        console.log('start date', ticketDate.format());
        const duration = moment.duration(ticketDate.diff(now));

        setDays(duration.days())
        setHours(padWithZeros(duration.hours()))
        setMinutes(padWithZeros(duration.minutes()))
        setSeconds(padWithZeros(duration.seconds()))
    }, 1000)

    useEffect(() => {
        startTimer
    }, [])

    useEffect(() => {
        console.log('timer done', getTimezoneDate(moment(), timezone).toDate(), getTimezoneDate(scheduledTime, timezone).toDate());
        // if current time is same as scheduled time - countdown is finished
        if (getTimezoneDate(scheduledTime, timezone).isSameOrBefore(getTimezoneDate(moment(), timezone))) {
            console.log('timer reset');
            // refetch tickets with countdown is finished 
            clearInterval(startTimer)
            reloadQuickPicks()
        }
    }, [seconds])

    return (
        <>
            {(isNaN(days) || (!Boolean(parseFloat(days)) && !Boolean(parseFloat(hours)) && !Boolean(parseFloat(minutes)) && !Boolean(parseFloat(seconds)))) ? (
                <LoadingContainer />
            ) : (
                <div id="countdown" className={`${showHeader ? 'icon' : ''}`}>
                    {showHeader && (<p className='small'>Tickets will be on sale in</p>)}
                    <div id="countdown-timer">
                        <Stack direction='horizontal' as="ul" className='justify-content-center'>
                            {Boolean(parseFloat(days)) && (
                                <Stack as="li">
                                    <span id="days" className='ticker'>{days}</span>
                                    <span>Days</span>
                                </Stack>
                            )}
                            {Boolean(parseFloat(hours)) && (
                                <Stack as="li">
                                    <span id="hours" className='ticker'>{hours}</span>
                                    <span>Hours</span>
                                </Stack>
                            )}
                            {Boolean(parseFloat(minutes)) && (
                                <Stack as="li">
                                    <span id="minutes" className='ticker'>{minutes}</span>
                                    <span>Minutes</span>
                                </Stack>
                            )}
                            <Stack as="li">
                                <span id="seconds" className='ticker'>{seconds}</span>
                                <span>Seconds</span>
                            </Stack>
                        </Stack>
                    </div>
                </div>
            )}
        </>
    );
}