import React from "react";

import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

export default function TicketNameBadge({ obj }) {

    return (
        <Badge bg="light" className="mt-2 text-dark badge-lg d-flex">
            {obj?.generalAdmission ? (
                <>General Admission</>
            ) : (
                <>
                    <Stack>
                        <span className="text-muted caption fw-normal">Sec</span>
                        <span>{obj?.sectionNumber}</span>
                    </Stack>
                    <Stack>
                        <span className="text-muted caption fw-normal">Row</span>
                        <span>{obj?.rowNumber}</span>
                    </Stack>
                    <Stack>
                        <span className="text-muted caption fw-normal">Seat</span>
                        <span>{obj?.seatNumber}</span>
                    </Stack>
                </>
            )}
        </Badge>
    )
}