import React, {
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { SpecialDiscount } from "../components/SpecialDiscount";
import "./specialDiscountProvider.scss"
// Create the context
const SpecialDiscountContext = createContext(undefined);

export const useSpecialDiscounts = () => {
  const context = useContext(SpecialDiscountContext);
  if (!context) {
    throw new Error("Context must be used within a SpecialDiscountProvider");
  }
  return context;
};

// Provide certain type of modals used in the page
export const SpecialDiscountProvider = ({ children }) => {
    const [tickets, setTickets] = useState([])
  const dialogRef = useRef(null);

  const openModal = (tickets) => {
    setTickets(tickets);
    dialogRef.current.showModal();
  };

  const closeModal = () => {
    dialogRef.current.close();
  };

  return (
    <SpecialDiscountContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      <dialog className="special_discount_dialog" ref={dialogRef}>
        <SpecialDiscount tickets={tickets} closeModal={closeModal} />
      </dialog>
    </SpecialDiscountContext.Provider>
  );
};
