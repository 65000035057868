import { Fragment } from "react";

import { useGACheckout } from "../../../NewGAPurchaseWrapper/GACheckoutProvider";

import { timezones } from "../../../../../utilities/helpers";

import Card from "react-bootstrap/Card";

import { GATickets } from "./GATickets";
import { Countdown } from './../../Countdown';
import { LoadingContainer } from "../../../../LoadingContainer";

import "../getStatus.scss";
import "./getTickets.scss";

// used for desktop and mobile
export default function GetTickets({ event, isScheduled, scheduledTime, quickpicks, selectQuickPick, isLoading, showTitle = true, asCard = true }) {

    const { checkingAvailability } = useGACheckout()

    let timezone = timezones(event?.venue?.timezone);

    return (
        <Fragment>
            <Card
                className={`${asCard ? 'card-xl card-xl--dark get_tickets_desktop get_desktop w-100' : 'bg-transparent border-0'}`}
            >
                <Card.Body className={`${asCard ? '' : 'p-0'}`}>
                    {showTitle && (<Card.Title as='h5'>Get Tickets 🎟️</Card.Title>)}
                    {isLoading ? (
                        <LoadingContainer />
                    ) : (
                        <>
                            {
                                isScheduled && scheduledTime ? (
                                    <Countdown scheduledTime={scheduledTime} timezone={timezone} />
                                ) : (
                                    <GATickets quickpicks={quickpicks} selectQuickPick={selectQuickPick} checkingAvailability={checkingAvailability} />
                                )
                            }
                        </>
                    )}
                </Card.Body>
            </Card>
        </Fragment>
    );
}
