import { NewCheckoutContainer } from "./NewCheckoutContainer";
import { ModalsProvider } from "./providers/ModalsProvider";
import { SpecialDiscountProvider } from "./providers/SpecialDiscountProvider";

export default function NewCheckoutWrapper() {
  return (
    <SpecialDiscountProvider>
      <ModalsProvider>
        <NewCheckoutContainer />
      </ModalsProvider>
    </SpecialDiscountProvider>
  );
}
