import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import "./terms.scss";

export default function Terms() {
  return (
    <Card className='card-xl card-xl--light terms'>
      <Card.Body className='info-text'>
        <Card.Text>
          By clicking "Complete Purchase", you agree to Blocktickets'{" "}
          <Link to='/purchase-policy' target='_blank'>
            Purchase Policy
          </Link>{" "}
          and{" "}
          <Link to='/terms-conditions' target='_blank'>
            Terms & Conditions
          </Link>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
