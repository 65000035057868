import React from 'react';
import { useInView } from "react-intersection-observer";

import Stack from 'react-bootstrap/Stack'
import Card from 'react-bootstrap/Card'

import rockyMountainVibes from '../../../assets/rocky-mountain-vibes.png';
import pioneerLeague from '../../../assets/pioneer-league.png';
import marthaVineyardSharks from '../../../assets/martha-vineyard-sharks.png';
import northShoreNavigators from '../../../assets/north-shore-navigators.png';
import newEnglandCollegiateLeague from '../../../assets/new-england-collegiate-league.png';
import oceanStateWaves from '../../../assets/ocean-state-waves.png';

export default function Partners() {

    // DEV make this better
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const partners = [
        {
            id: "1",
            name: "Rocky Mountain Vibes",
            logo: rockyMountainVibes
        },
        {
            id: "2",
            name: "Pioneer League",
            logo: pioneerLeague
        },
        {
            id: "3",
            name: "Martha's Vineyard Sharks",
            logo: marthaVineyardSharks
        },
        {
            id: "4",
            name: "North Shore Navigators",
            logo: northShoreNavigators
        },
        {
            id: "5",
            name: "New England Collegiate League",
            logo: newEnglandCollegiateLeague
        },
        {
            id: "6",
            name: "Ocean State Waves",
            logo: oceanStateWaves
        }
    ]

    return (
        <section className='partners'>
            <div ref={ref}>
                <div className={`partners_inner_div info-container ${inView ? 'text_appear_two' : ''}`}>
                    <h1 className="display-4 fw-semi-bold pb-2 text-md-center">Meet Some of Our Partners.</h1>
                    <p className='display-text'>These teams put their trust in us to deliver a seamless ticketing experience that maximizes revenue and strengthens fan loyalty.</p>
                    <Stack gap={4} className='mt-5 partners-container flex-lg-row justify-content-center align-items-center'>
                        {partners?.map(partner => (
                            <Card body id={`partner-${partner.id}`} className="d-flex-column justify-content-between">
                                <Card.Img src={partner.logo} alt={partner.name} />
                                <Card.Text className='text-nowrap fw-semi-bold'>{partner?.name}</Card.Text>
                            </Card>
                        ))}
                    </Stack>
                </div>
            </div>
        </section>
    );
}
