import React, { Fragment } from 'react';

import { getSinglarOrPluralWord } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

export default function DisplayTickets({ role, status, tickets, selectedTickets, setSelectedTickets }) {

    const handleChange = (val) => setSelectedTickets(val);

    // tickets should be tickets or selectedTickets depending on role
    tickets = tickets ? tickets : selectedTickets;

    return (
        <Fragment>
            {role === 'select' && (
                <h6 className="modal-body-heading-title mb-4">Select tickets to {status}</h6>
            )}
            <Stack direction="horizontal" className="split-row">
                <div>
                    <span className="small small-bold">{tickets[0]?.generalAdmission ? 'General Admission' : `Sec ${tickets[0]?.sectionNumber} \u2022 Row ${tickets[0]?.rowNumber}`}</span>
                </div>
                <div className='flex-shrink-0'>
                    <span className="num-tickets">{tickets?.length} {getSinglarOrPluralWord(tickets?.length)} </span>
                </div>
            </Stack>
            {role === 'select' ? (
                <ToggleButtonGroup
                    type="checkbox"
                    onChange={handleChange} className="flex-wrap tickets-btn-group">
                    {
                        tickets && tickets.map((ticket, index) => (
                            <ToggleButton className="ticket-pick" id={`tbg-btn-${index}`} variant="default" value={ticket} key={index}>
                                {ticket?.generalAdmission ? 'GA' : (
                                    <Stack gap={1}>
                                        <span className='label'>Seat</span>
                                        <span>{ticket?.seatNumber}</span>
                                    </Stack>
                                )}
                            </ToggleButton>
                        ))}
                </ToggleButtonGroup>
            ) : (
                <Stack direction="horizontal" className="btn-group flex-wrap">
                    {selectedTickets && selectedTickets.map((ticket, index) => (
                        <div className="btn ticket-pick selected" key={index}>
                            {ticket?.generalAdmission ? 'GA' : (
                                <Stack gap={1}>
                                    <span className='label'>Seat</span>
                                    <span>{ticket?.seatNumber}</span>
                                </Stack>
                            )}
                        </div>
                    ))}

                </Stack>
            )}
        </Fragment>
    );
}