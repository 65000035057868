import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { formatShortDate, getStartDateFormatter, formatCurrency, formatShortAddress, getTimezoneDate, timezones, getSinglarOrPluralWord } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { TicketNameBadge } from '../TicketNameBadge';

import placeholder from '../../assets/placeholder.png';

import './ticketCard.scss';

export default function TicketCard({ id, ticketStatus, ticketState, order, listing, guestList, handleClick }) {

    let obj = listing || order || guestList;

    let event = obj?.event;

    let timezone = timezones(event?.venue?.timezone)

    if (listing) {
        order = {}
        order['event'] = listing?.event;
        order['orderId'] = listing?.uuid;
    }
    useEffect(() => {
    }, [ticketState, ticketStatus, guestList])

    return (
        <Fragment>
            <Card body className="ticket-card">
                <Card.Img
                    src={event?.image?.url || placeholder}
                    alt={`Cover art for ${event?.name} event`}
                    width="217"
                    height="217"
                    className="event-image event-image-lg mb-3"
                />
                <div className="details d-flex-column">
                    <Card.Title as="h5" className='normal'>{event?.name}</Card.Title>
                    <p className="event-details">
                        {formatShortDate(getTimezoneDate(event?.start, timezone), getStartDateFormatter(event))}<span className="venue">{event?.venue.name}</span><span className="loc">
                            {formatShortAddress(event?.venue)}
                        </span>
                    </p>

                    {(!id && !guestList) && (<span className="num-tickets">{obj?.tickets?.length} {getSinglarOrPluralWord(obj?.tickets?.length)} </span>
                    )}

                    {guestList &&
                        (<span className="num-tickets">{obj?.guest_passes.length} {getSinglarOrPluralWord(obj?.guest_passes.length, 'Guest Pass')}</span>)
                    }

                    <>
                        {ticketState &&
                            (
                                <>
                                    <Stack className='mb-2'>
                                        <Stack direction="horizontal" className="split-row mb-1">
                                            <span className='m-0 caption'>Listing price per ticket</span>
                                            <span className='text-end fw-medium'>{formatCurrency(listing?.askingPrice)}</span>
                                        </Stack>
                                        <p className='caption text-muted'>You will make {formatCurrency((listing?.payout / listing?.tickets.length))} per ticket</p>
                                    </Stack>
                                </>
                            )
                        }

                        {id || ticketStatus && (
                            <TicketNameBadge obj={obj?.tickets[0]} />
                        )}

                        {ticketState && ticketState === 'active' &&
                            (
                                <>
                                    <Stack direction="horizontal" gap={3} className="mt-3 btn-group-flex">
                                        <Button onClick={() => handleClick('remove', listing)}>Remove listing</Button>
                                        <Button onClick={() => handleClick('edit', listing)} variant="outline-light" size="xs">Edit</Button>
                                    </Stack>
                                </>
                            )
                        }

                        {id || (ticketState && ticketState !== "active") && (
                            <Stack direction="horizontal" gap={3} className="mt-3 btn-group-flex">
                                <Button variant='outline-light' size="xs" className="flex-grow-1" onClick={() => handleClick('details', listing)}>Details</Button>
                            </Stack>
                        )}

                        {
                            !id && !ticketStatus && !guestList &&
                            (
                                <Link to={`/event-details/${order?.orderId}`} className="btn btn-primary">
                                    Event details
                                </Link>
                            )
                        }

                        {
                            /* Guest List button */
                            guestList &&
                            (
                                <Link to={`/guest-passes/${event.uuid}`} className="btn btn-primary">
                                    Event details
                                </Link>
                            )
                        }
                    </>
                </div>
            </Card>
        </Fragment>
    );
}
