import { useEffect, useState } from "react";

import { getSinglarOrPluralWord } from "../../../../utilities/helpers";

import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";

import { QuickpickBadge } from "../../NewSeatedPurchaseWrapper/NewSeatedPurchaseContainer/TicketSelectionPanel/ListingsContainer/PurchaseOption/PurchaseOption";

import placeholder from "../../../../assets/placeholder.png";

import "./buyingThis.scss";

export default function BuyingThis({ cart, event }) {
    const [quickpicks, setQuickpicks] = useState([]);
    useEffect(() => {
        console.log("cart: ", cart);
        console.log("event: ", event);
        console.log("listings: ", cart?.listings);
        console.log("inventories: ", cart?.am_inventories);
        console.log("rows: ", cart?.am_rows);

        if (cart?.listings || cart?.am_inventories || cart?.am_rows) {
            const ticketRelations = JSON.parse(cart?.ticketRelations);
            console.log("ticketRelations: ", ticketRelations);
            setQuickpicks([
                ...cart?.am_inventories.map((inventory) =>
                    transformInventory(inventory, ticketRelations, cart.tickets)
                ),
                ...cart?.am_rows.map((row) =>
                    transformRow(row, ticketRelations, cart.tickets)
                ),
                ...cart?.listings.map((listing) =>
                    transformListing(listing, ticketRelations, cart.tickets)
                ),
            ]);
        }
    }, [cart, event]);

    useEffect(() => {
        console.log("Quickpicks: ", quickpicks);
    }, [quickpicks]);
    // Might need type and other params for inventories/rows

    if (!cart || !cart.tickets || cart.tickets.length === 0) {
        return null;
    }

    function transformListing(listing, ticketRelations, tickets) {
        // Find all ticket relations for this listing
        console.log("listing transformListing", listing)
        console.log("listing ticketRelations", ticketRelations)
        console.log("listing tickets", tickets)

        const listingTicketRelations = ticketRelations.filter(
            (relation) =>
                relation.source.type === "listing" && relation.source.id === listing.id.toString()
        );
        console.log("listingTicketRelations: ", listingTicketRelations)

        // Get the actual tickets for this listing
        const listingTickets = tickets.filter((ticket) =>
            listingTicketRelations.some((relation) => relation.ticketId === ticket.id)
        );
        console.log("listingTickets: ", listingTickets)

        return {
            availableCount: listing.quantity,
            listing: listing,
            price: listing.askingPrice,
            sectionId: listing.sectionId,
            rowId: listing.rowId,
            sectionNumber: listing.sectionNumber,
            rowNumber: listing.rowNumber,
            type: listing.type,
            tickets: listingTickets,
        };
    }

    function transformRow(pick, ticketRelations, tickets) {
        // Determine the source type based on whether it's seated or GA
        const sourceType = "row";
        const sourceId = pick?.id;

        // Find relevant ticket relations
        const pickTicketRelations = ticketRelations.filter(
            (relation) =>
                relation.source.type === sourceType && relation.source.id === sourceId
        );

        // Get the actual tickets
        const pickTickets = tickets.filter((ticket) =>
            pickTicketRelations.some((relation) => relation.ticketId === ticket.id)
        );

        return {
            sectionId: pick?.am_section?.sectionId,
            sectionNumber: pick?.sectionNumber,
            rowNumber: pick?.rowNumber,
            type: "SEATED",
            offer: pick?.offers[0],
            tickets: pickTickets,
        };
    }

    function transformInventory(pick, ticketRelations, tickets) {
        // Determine the source type based on whether it's seated or GA
        const sourceType = "inventory";
        const sourceId = pick?.id;

        // Find relevant ticket relations
        const pickTicketRelations = ticketRelations.filter(
            (relation) =>
                relation.source.type === sourceType && relation.source.id === sourceId
        );

        // Get the actual tickets
        const pickTickets = tickets.filter((ticket) =>
            pickTicketRelations.some((relation) => relation.ticketId === ticket.id)
        );

        return {
            sectionId: pick?.am_section?.sectionId,
            sectionNumber: pick?.sectionNumber,
            rowNumber: pick?.rowNumber,
            type: "GA",
            offer: pick?.offers[0],
            tickets: pickTickets,
        };
    }

    let getTicketImage = (sectionId) => {
        const images = event?.seatmap?.images;
        const match = images?.find((image) => image.name.includes("0857108a-f4ec-4241-8862-fbd24750c8fe"));// "sectionId"

        if (match?.url) {
            return match.url;
        } else {
            return placeholder;
        }
    };

    return (
        <div className='buying-this-container'>
            {quickpicks.map((pick, i) => {
                return (
                    <TicketCard key={i} pick={pick} getTicketImage={getTicketImage} />
                );
            })}
        </div>
    );
}

const TicketCard = ({ getTicketImage, pick }) => {
    const getSeats = (seats) => {
        if (seats.length === 0) return "";
        seats.sort((a, b) => a - b);
        const ranges = [];
        let start = seats[0];
        let prev = seats[0];

        for (let i = 1; i <= seats.length; i++) {
            if (i === seats.length || seats[i] !== prev + 1) {
                ranges.push(start === prev ? start.toString() : `${start}-${prev}`);
                start = seats[i];
            }
            prev = seats[i];
        }

        return `${getSinglarOrPluralWord(seats?.length, "Seat")}: ${ranges.join(
            ", "
        )}`;
    };

    let isGeneralAdmission = pick?.type === "GA";

    return (
        <Card className='card-xl card-xl--light buying-this'>
            <Card.Body className='d-flex-column gap-2'>
                <div className='d-flex col-divider'>
                    <div className='vjx-img-wrap'>
                        <img
                            src={getTicketImage(pick?.sectionId)}
                            alt='Seat view for this ticket'
                        />
                    </div>
                    <div className='ticket-info'>
                        <div className='col'>
                            <Stack direction='horizontal' gap={2} className='flex-wrap'>
                                <QuickpickBadge
                                    isListing={Boolean(pick?.listing)}
                                    listing={pick?.listing}
                                    inventoryType={pick?.offer?.inventoryType}
                                    offerName={pick?.offer?.name}
                                />
                            </Stack>
                            <Stack className='gap-md-2 align-items-start mt-2'>
                                <Card.Title
                                    as='h5'
                                    className={`ticket-loc ${isGeneralAdmission ? "ticket" : "seat"
                                        } ticket-loc`}
                                >
                                    {isGeneralAdmission
                                        ? "General Admission"
                                        : `Sec ${pick.sectionNumber} • Row ${pick.rowNumber}`}
                                </Card.Title>

                                <div className='tablet-desktop-only small'>
                                    {isGeneralAdmission ? (
                                        <Card.Text>This is a standing area 🧑‍🤝‍🧑</Card.Text>
                                    ) : (
                                        <Card.Text>
                                            {getSeats(pick.tickets.map((t) => Number(t.seatNumber)))}
                                        </Card.Text>
                                    )}
                                </div>
                            </Stack>
                        </div>
                        <div className='tablet-desktop-only'>
                            <div className='right-side'>
                                <div className='ticket-count-info'>
                                    <span className='ticket-count'>{pick.tickets?.length}</span>
                                    <span className='ticket-text'>
                                        {getSinglarOrPluralWord(pick.tickets?.length)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Stack
                    direction='horizontal'
                    className='pt-2 justify-content-between align-items-center mobile-only small'
                >
                    {isGeneralAdmission ? (
                        <span>This is a standing area 🧑‍🤝‍🧑</span>
                    ) : (
                        <span>
                            {getSeats(pick.tickets.map((t) => Number(t.seatNumber)))}
                        </span>
                    )}
                    <span>
                        {pick.tickets?.length}{" "}
                        {getSinglarOrPluralWord(pick.tickets?.length)}
                    </span>
                </Stack>
            </Card.Body>
        </Card>
    );
};
