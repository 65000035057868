import Card from "react-bootstrap/Card";
import { FilterBar } from "./FilterBar";
import { ListingsContainer } from "./ListingsContainer";
import { FindOnMap } from "../../../components/FindOnMap";

export default function TicketSelectionPanel({
    isScheduled,
    scheduledTime,
}) {
    return (
        <div
            className='ticketpanel-height card-xl-no-mobile card-xl-no-mobile--dark d-flex-column vjx-full'
        >
            <div className='card-body-heading vjx-7 pxt-m'>
                <div className='tablet-desktop-only'>
                    <Card.Title as='h5'>Select your seats!</Card.Title>
                    <Card.Subtitle as='h6' className='vjx-lh'>
                        Time to browse through the available options.
                    </Card.Subtitle>
                </div>
                <FindOnMap extraClass='vjx-8' isScheduled={isScheduled} />
            </div>
            {!isScheduled && <FilterBar />}
            <ListingsContainer
                isScheduled={isScheduled}
                scheduledTime={scheduledTime}
            />
        </div>
    );
}
