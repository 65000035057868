import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import { shouldHideNav } from "./utilities/helpers";

import { Navigation } from "./components";

const ConditionalContainerNav = ({ children }) => {

    const location = useLocation()

    // control hide navigation
    useLayoutEffect(() => {
        if (shouldHideNav(location.pathname)) {
            // hide navbar and remove container padding on mobile 
            document.querySelector("#navbar").classList.add('tablet-desktop-only')
            document.querySelector("#main-container").classList.add('mobile-p0')
        }

        return () => {
            document.querySelector("#navbar").classList.remove('tablet-desktop-only')
            document.querySelector("#main-container").classList.remove('mobile-p0')

        };
    }, [location.pathname]);

    return (
        <>
            <Navigation />
            <div className="container" id='main-container'>
                {children}
            </div>
        </>
    );
};

export default ConditionalContainerNav;
