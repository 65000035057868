import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import './addSubtractQuantity.scss'
export default function AddSubtractQuantity({
  whiteBG,
  quantity,
  increment,
  decrement
}) {
  return (
    <Stack direction='horizontal' className={`fw-bolder vjx-ticket-counter ${whiteBG ? '--white' : '--dark'}`}>
      <Button
        title='decrease quantity of tickets'
        className='btn--icon'
        variant='outline-light'
        onClick={decrement}
        disabled={quantity === 0}
        aria-disabled={quantity === 0}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          role='img'
          width='20'
          height='2'
          viewBox='0 0 20 2'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.666992 1C0.666992 0.447715 1.11471 0 1.66699 0H18.3337C18.8859 0 19.3337 0.447715 19.3337 1C19.3337 1.55228 18.8859 2 18.3337 2H1.66699C1.11471 2 0.666992 1.55228 0.666992 1Z'
          />
        </svg>
      </Button>
      <div className="ticket-count">
        <span>{quantity}</span>
      </div>
      <Button
        title='increase quantity of tickets'
        className='btn--icon ms-0'
        variant='outline-light'
        onClick={increment}
        // disabled={listing ? true : (ticketCount >= (ticket.resale ? 1 : ticket.maximum_quantity) || ticketCount >= ticketContext.generalAdmissionCount)}
        // aria-disabled={listing ? true : ticket ? ticketCount >= ticket.maximum_quantity : true}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          role='img'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.0003 1.66699C11.0003 1.11471 10.5526 0.666992 10.0003 0.666992C9.44804 0.666992 9.00033 1.11471 9.00033 1.66699V9.00033H1.66699C1.11471 9.00033 0.666992 9.44804 0.666992 10.0003C0.666992 10.5526 1.11471 11.0003 1.66699 11.0003H9.00033V18.3337C9.00033 18.8859 9.44804 19.3337 10.0003 19.3337C10.5526 19.3337 11.0003 18.8859 11.0003 18.3337V11.0003H18.3337C18.8859 11.0003 19.3337 10.5526 19.3337 10.0003C19.3337 9.44804 18.8859 9.00033 18.3337 9.00033H11.0003V1.66699Z'
          />
        </svg>
      </Button>
    </Stack>
  );
}
