import { useEffect, useState } from "react";
import { GAModalsProvider } from "./GAModalsProvider";
import GAPurchaseContainer from "./GAPurchaseContainer/GAPurchaseContainer";
import "../purchaseFlow.scss";
import "./newGAPurchaseWrapper.scss";
import { getAvailableSections } from "../../../utilities/api";
import { GACheckoutProvider } from "./GACheckoutProvider";

export default function NewGAPurchaseWrapper({
    event,
    tickets,
    listings,
    generalAdmissionCount,
    generalAdmissionTicket,
    resaleTickets,
}) {
    const [quickpicks, setQuickpicks] = useState([]);
    const [isSoldout, setIsSoldout] = useState(false);
    const [selectedFromQuickpicks, setSelectedFromQuickpicks] = useState(null); // { quantity, quickpick }
    const [isLoading, setIsLoading] = useState(false)
    const [isScheduled, setIsScheduled] = useState(false)
    const [scheduledTime, setScheduledTime] = useState(false)

    // Hide intercom widget on this page
    // This does hide it until you refresh the page / turn it back on again
    useEffect(() => {
        if (window.Intercom) {
            window.Intercom("update", {
                hide_default_launcher: true,
            });
        }
    }, []);

    const getAvailability = async () => {
        setIsLoading(true)
        const res = await getAvailableSections(event.id);
        console.log("RES getAvailableSections: ", res.data);
        setQuickpicks([...res.data.availableSectionsAndRows, listings.map(transformListing)])
        setIsScheduled(res.data.isScheduled)
        setScheduledTime(res.data.scheduledTime)
        if (res.data.soldOut) {
            setIsSoldout(true);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        if (event) {
            getAvailability();
        }
    }, [event]);

    // Format listing more like quickPicks for uniform handling // more uniform handling
    function transformListing(listing) {
        return {
            availableCount: listing.quantity,
            listing: listing,
            price: listing.askingPrice,
            sectionId: listing.sectionId,
            rowId: listing.rowId,
            type: listing.type,
        };
    }

    const selectQuickPick = (pick, quantity) => {
        console.log("selectQuickPick: ", { pick, quantity });
        setSelectedFromQuickpicks({ pick, quantity });
    };

    return (
        <GACheckoutProvider event={event} selectedFromQuickpicks={selectedFromQuickpicks}>
            <GAModalsProvider event={event} quickpicks={quickpicks} selectQuickPick={selectQuickPick} isLoading={isLoading} isScheduled={isScheduled} scheduledTime={scheduledTime}>
                <GAPurchaseContainer isLoading={isLoading} selectedFromQuickpicks={selectedFromQuickpicks} event={event} isScheduled={isScheduled} scheduledTime={scheduledTime} listings={listings} isSoldout={isSoldout} quickpicks={quickpicks} selectQuickPick={selectQuickPick} />
            </GAModalsProvider>
        </GACheckoutProvider>
    );
}
