import { useEffect, useState } from "react";

import { formatCurrency } from '../../../../../../utilities/helpers';

import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import DetailsButton from "../../../DetailsButton/DetailsButton";
import { AddSubtractQuantity } from "../../../AddSubtractQuantity";
import { Spinner } from "../../../../../LoadingContainer/Spinner";

import "./GATickets.scss";

export default function GATickets({ quickpicks, selectQuickPick, checkingAvailability }) {
    const [openIndex, setOpenIndex] = useState(0);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);

    console.log("GATICKETS quickpicks: ", quickpicks);
    return (
        <ul className="vjx-ga-ul">
            {quickpicks.map((quickpick, index) => {
                // Check if quickpick is not an empty array and has properties
                if (Array.isArray(quickpick) && quickpick.length === 0) {
                    return null; // Don't render anything for empty arrays
                }

                return (
                    <GATicket
                        key={index}
                        index={index}
                        open={openIndex === index}
                        setOpen={() => setOpenIndex(index)}
                        detailsOpen={detailsOpen}
                        setDetailsOpen={setDetailsOpen}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        quickpick={quickpick}
                        selectQuickPick={selectQuickPick}
                        checkingAvailability={checkingAvailability}
                    />
                );
            })}
        </ul>
    );
}

function GATicket({
    index,
    open,
    setOpen,
    detailsOpen,
    setDetailsOpen,
    quantity,
    setQuantity,
    quickpick,
    selectQuickPick,
    checkingAvailability
}) {
    const offer = quickpick.offer;

    useEffect(() => {
        if (!open) {
            setDetailsOpen(false);
        }
    }, [open, setDetailsOpen]);

    const handleDetailsButtonClick = (e) => {
        e.stopPropagation(); // Prevent the click from bubbling up to the parent button
        setDetailsOpen(!detailsOpen);
    };

    console.log("gaTicket: ", quickpick)
    return (
        <li
            onClick={setOpen}
            aria-controls={`ticket-collapse-text-${index}`}
            aria-expanded={open}
            className={`vjx-ga-ticket ${open ? "--active" : ""}`}
        >
            <div className='vjx-ga-txt-col'>
                <span className='vjx-ticket-type'>GA (Standing Room)</span>
                <span className='vjx-ticket-price'>{formatCurrency(quickpick?.price)}</span>
            </div>
            <div className='vjx-ga-txt-col'>
                <QuickpickBadge
                    isListing={false} // Val: change this to true if pick is listing
                    listing={pick?.listing}
                    inventoryType={offer?.inventoryType}
                    offerName={offer?.name}
                />
                <span className='vjx-ticket-taxes'>Incl. Taxes & Fees</span>
            </div>
            <Collapse in={open}>
                <div id={`ticket-collapse-text-${index}`}>
                    <div className='vjx-collapse-one'>
                        {offer?.description ? (
                            <DetailsButton
                                text={"Ticket details"}
                                onClick={handleDetailsButtonClick}
                                aria-controls={`ticket-details-text-${index}`}
                                aria-expanded={detailsOpen}
                            />
                        ) : (
                            <div></div>
                        )}
                        <div className='vjx-quantity-button'>
                            {/*
                                TODO ADD BACK w increment + decrement functions
                            <AddSubtractQuantity
                                whiteBG={true}
                                quantity={quantity}
                                setQuantity={setQuantity}
                            /> */}
                            <Button
                                onClick={(e) => selectQuickPick(quickpick, quantity)}
                                disabled={checkingAvailability}
                                variant='primary'
                                size='lg'
                                style={{ width: '128px', height: '48px' }}
                                className='d-flex justify-content-center m-0'
                            >
                                {checkingAvailability ? (<Spinner />) : 'Checkout'}
                            </Button>
                        </div>
                    </div>
                </div>
            </Collapse>
            <Collapse in={detailsOpen}>
                <div id={`ticket-details-text-${index}`}>
                    <div className='vjx-collapse-two'>
                        <span>{offer?.description}</span>
                    </div>
                </div>
            </Collapse>
        </li>
    );
}