import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { getQuickpicks } from "../../../../../utilities/api";
import { useLocation, useNavigate } from "react-router-dom";

export const TicketContext = createContext();

export const useTickets = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error("Context must be used within a TicketsProvider");
  }
  return context;
};

// Provides all state/functionality for ticket processing
export const TicketsProvider = ({
  tickets,
  listings,
  offers,
  event,
  code,
  priceRangeMinMax,
  eventTicketLimit,
  children,
}) => {
  // Each type of selection has its own state
  // because we need different details from each to display + to buy
  const [selectedFromSeats, setSelectedFromSeats] = useState([]); // [{ seatId, seatInfo }]
  const [selectedFromGA, setSelectedFromGA] = useState([]); // [{ quantity, price, resale, (listing)/(offer, inventoryDBId) }]
  const [selectedFromQuickpicks, setSelectedFromQuickpicks] = useState(null); // { quantity, quickpick }

  const [totalCount, setTotalCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [quickpicks, setQuickpicks] = useState([]);
  const [isLoadingTickets, setIsLoadingTickets] = useState(true);

  // FILTERING
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const quantityParam = searchParams.get("quantity");
  const sortParam = searchParams.get("sort");
  const accessCodeParam = searchParams.get("access_code");

  const initialQuantity = quantityParam ? parseInt(quantityParam, 10) : 2;
  const intialSort = sortParam === "bestseat" ? "bestseat" : "price";

  const [soldout, setSoldout] = useState(false);
  const [accessCodes, setAccessCodes] = useState(
    accessCodeParam ? accessCodeParam.split(",") : []
  );
  const [sort, setSort] = useState(intialSort);
  const [quantity, setQuantity] = useState(initialQuantity);
  const [accessible, setAccessible] = useState(false);

  // Need to check if these exist in searchParams
  // then check min/max data -> set to min and max
  // shouldn't min/max data come from priceRange state in NewSeatedPurchaseWrapper component?
  const [priceRange, setPriceRange] = useState([0, 500]);
  const [originalPriceRange, setOriginalPriceRange] =
    useState(priceRangeMinMax);
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(false);

  useEffect(() => {
    // Calculate total count
    const seatedCount = selectedFromSeats.length;
    const gaCount = selectedFromGA.reduce(
      (sum, item) => sum + item.quantity,
      0
    );
    const newTotalCount = seatedCount + gaCount;

    // Calculate total price
    const seatedPrice = selectedFromSeats.reduce(
      (sum, item) => sum + item.seatInfo.price,
      0
    );
    const gaPrice = selectedFromGA.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    const newTotalPrice = seatedPrice + gaPrice;

    setTotalCount(newTotalCount);
    setTotalPrice(newTotalPrice);

    console.log("selectedFromSeats: ", selectedFromSeats);
    console.log("selectedFromGA: ", selectedFromGA);
    console.log("selectedFromQuickpicks: ", selectedFromQuickpicks);
  }, [selectedFromSeats, selectedFromGA, selectedFromQuickpicks]);

  useEffect(() => {
    console.log("selectedFrom");
    // First, unhighlight all seats
    document.querySelectorAll(".highlight").forEach((element) => {
      element.classList.remove("highlight");
    });

    // Create a map to keep track of listings and their selected tickets
    const listingMap = new Map();

    // Populate the listingMap
    selectedFromSeats.forEach(({ seatId, seatInfo }) => {
      if (seatInfo.listing) {
        const listingId = seatInfo.listing.id; // Assuming each listing has a unique id
        if (!listingMap.has(listingId)) {
          listingMap.set(listingId, {
            listing: seatInfo.listing,
            selectedCount: 0,
            totalCount: seatInfo.listing.tickets.length,
          });
        }
        listingMap.get(listingId).selectedCount++;
      }
    });

    // Highlight seats based on the listingMap
    listingMap.forEach(({ listing, selectedCount, totalCount }) => {
      if (selectedCount > 0) {
        // selectedCount < totalCount
        // Highlight all seats in this listing
        listing.tickets.forEach((ticket) => {
          const seatElement = document.getElementById(ticket.name);
          if (seatElement) {
            seatElement.classList.add("highlight");
          }
        });
      }
    });
  }, [selectedFromSeats]);

  useEffect(() => {
    const isAccessible = searchParams.get("accessible") === "true";
    const quantity = parseInt(searchParams.get("quantity"), 10) || 2;
    const sort = searchParams.get("sort") === "price" ? "price" : "bestseat";
    const accessCodesString = searchParams.get("access_code");
    const accessCodes = accessCodesString ? accessCodesString.split(",") : [];

    const offerIdsFromUrl = searchParams.get("offers");
    const selectedOfferIds = offerIdsFromUrl
      ? offerIdsFromUrl.split(",").map((id) => parseInt(id, 10))
      : [];

    let priceRange;
    const priceRangeFromUrl = searchParams.get("price");

    if (priceRangeMinMax) {
      const [minAvailable, maxAvailable] = priceRangeMinMax;

      if (priceRangeFromUrl) {
        const [minFromUrl, maxFromUrl] = priceRangeFromUrl
          .split(",")
          .map((price) => parseInt(price, 10));

        priceRange = [
          Math.max(minFromUrl, minAvailable),
          Math.min(maxFromUrl, maxAvailable),
        ];
      } else {
        priceRange = [minAvailable, maxAvailable];
      }
    } else {
      // If priceRangeMinMax is not available, use URL values or default
      priceRange = priceRangeFromUrl
        ? priceRangeFromUrl.split(",").map((price) => parseInt(price, 10))
        : [0, 5000]; // Default price range if not provided
    }

    // event.id, quantity, priceRange, selectedOfferIds
    if (!event) return;
    getQuickPicksAndListings(
      event,
      quantity,
      priceRange,
      selectedOfferIds,
      accessCodes,
      isAccessible,
      sort
    );

    setQuantity(quantity);
    setSort(sort);
    setAccessCodes(accessCodes);
    setSelectedOfferIds(selectedOfferIds);
    setPriceRange(priceRange);
    setAccessible(isAccessible);
  }, [location.search]);

  const reloadQuickPicks = () => {
    getQuickPicksAndListings(
      event,
      quantity,
      priceRange,
      selectedOfferIds,
      accessCodes,
      accessible,
      sort
    );
  };

  const updateSearchParam = (key, value) => {
    searchParams.set(key, value);
    navigate(`${location.pathname}?${searchParams}`);
  };

  const multiUpdateSearchParam = (listOfKVs) => {
    listOfKVs.forEach((kv) => {
      searchParams.set(kv[0], kv[1]);
    });
    navigate(`${location.pathname}?${searchParams}`);
  };

  // Format listing more like quickPicks for uniform handling // more uniform handling
  function transformListing(listing) {
    return {
      availableCount: listing.quantity,
      listing: listing,
      price: listing.askingPrice,
      sectionId: listing.sectionId,
      rowId: listing.rowId,
      sectionNumber: listing.sectionNumber,
      rowNumber: listing.rowNumber,
      type: listing.type,
    };
  }

  const getQuickPicksAndListings = async (
    event,
    quantity,
    priceRange,
    offerIds,
    accessCodes,
    accessible,
    sort
  ) => {
    setIsLoadingTickets(true);
    try {
      let data = {
        event,
        quantity,
        priceRange,
        offerIds,
        accessCodes,
        accessible,
        sort,
      };
      let res = await getQuickpicks(data);
      console.log("quickpicks res:", res.data);
      console.log("quickpicks listings res:", listings);

      setIsScheduled(res.data.isScheduled);
      setScheduledTime(res.data.scheduledTime);

      // Only add listings if we're not filtering by specific offerIds
      if (offerIds.length === 0 && !accessible) {
        setQuickpicks([
          ...res.data.availableSectionsAndRows,
          ...listings.map(transformListing),
        ]);
        console.log("quickpicks: ", [
          ...res.data.availableSectionsAndRows,
          ...listings.map(transformListing),
        ]);
      } else {
        // If offerIds is not empty, only add availableSectionsAndRows
        setQuickpicks([...res.data.availableSectionsAndRows]);
        console.log("quickpicks: ", [...res.data.availableSectionsAndRows]);
      }
      if (res.data.soldout) {
        console.log("event is soldout...");
        setSoldout(true);
      }
    } catch (error) {
      console.error("Error fetching quickpicks:", error);
    } finally {
      setIsLoadingTickets(false);
    }
  };

  const selectQuickPick = (pick) => {
    console.log("selectQuickPick: ", { pick, quantity });
    setSelectedFromQuickpicks({ pick, quantity });
  };

  const resetMapSelection = () => {
    setSelectedFromGA([]);
    setSelectedFromSeats([]);
  };

  const imageUrlMap = useMemo(() => {
    return event?.seatmap?.images?.reduce((map, image) => {
      map[image.name] = image.url;
      return map;
    }, {});
  }, [event?.seatmap?.images]);

  const getTicketImage = (sectionId) => {
    console.log("imageUrlMap: ", imageUrlMap)
    return imageUrlMap?.["0857108a-f4ec-4241-8862-fbd24750c8fe" + ".jpg"]; // sectionId
  };

  // Does not change the quantity being searched for
  // Just changes the current pick quantity
  const changeQuickpickQuantity = (newQuantity) => {
    // Disallow if listing
    // we only show the user the applicable amounts anyways
    setSelectedFromQuickpicks((prev) => ({
      ...prev,
      quantity:
        typeof newQuantity === "string" ? Number(newQuantity) : newQuantity,
    }));
  };

  return (
    <TicketContext.Provider
      value={{
        tickets,
        listings,
        isLoadingTickets,
        offers,
        event,
        code,
        isScheduled,
        scheduledTime,
        quickpicks,
        sort,
        quantity,
        priceRange,
        updateSearchParam,
        multiUpdateSearchParam,
        selectedFromQuickpicks,
        selectQuickPick,
        selectedFromSeats,
        selectedFromGA,
        setSelectedFromSeats,
        setSelectedFromGA,
        selectedOfferIds,
        totalCount,
        totalPrice,
        originalPriceRange,
        eventTicketLimit,
        accessCodes,
        soldout,
        resetMapSelection,
        getTicketImage,
        changeQuickpickQuantity,
        reloadQuickPicks,
        accessible,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
