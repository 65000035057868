import "./confirmExitModal.scss";
import Button from "react-bootstrap/Button";

export default function ConfirmExitModal({ dialogRef, confirm, cancel }) {
    return (
        <dialog ref={dialogRef} className={`dialog-confirmexitmap`}>
            <div className='confirmexitmap-content'>
                <h4>Are you sure you want to exit?</h4>
                <span>You will lose your selected tickets....</span>
                <div className="btn-group-flex d-flex d-flex-column">
                    <Button onClick={confirm} type='primary' size='lg'>
                        Exit anyway
                    </Button>
                    <Button onClick={cancel} variant="outline-light" size='lg'>
                        Cancel
                    </Button>
                </div>
            </div>
        </dialog>
    );
}
