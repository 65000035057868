import React from 'react';
import Button from 'react-bootstrap/Button';
import "./ticketErrorModal.scss"

export default function TicketErrorModal({ dialogRef, seatedError, handleClose }) {
    return (
        <dialog ref={dialogRef} className='ticket-error-dialog'>
            <div className="ticket-error-content">
                <h4>{seatedError?.title}</h4>
                <span>{seatedError?.message}</span>
                <Button onClick={handleClose} type='primary' size='lg'>
                    {seatedError?.buttonText}
                </Button>
            </div>
        </dialog>
    )
}