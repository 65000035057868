import React, { Fragment } from 'react';

import { formatShortDate, getStartDateFormatter, formatShortAddress, getTimezoneDate, timezones, getUrl } from '../../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import { IconButton } from '../../../IconButton';

export default function EventCardBody({ event }) {

    let timezone = timezones(event?.venue?.timezone)
    console.log("EventCardBody event: ", event)
    return (
        <Fragment>
            <Card.Title as="h5">{event?.name}</Card.Title>
            <Card.Subtitle as="h6">{event?.venue?.name}</Card.Subtitle>
            <hr />
            <ul>
                <li>
                    <Row>
                        <span className="col caption">{formatShortDate(getTimezoneDate(event?.start, timezone), getStartDateFormatter(event))}</span>
                        <span className="col caption text-end">{formatShortAddress(event?.venue)}</span>
                    </Row>
                </li>
            </ul>
            <IconButton
                variant="outline-light"
                styles="text-primary"
                link={getUrl(`/e/${event?.seoUrl}/${event?.shortCode}`, event?.seatmap?.ga_only)}
                btn="ticket--primary">
                Get Tickets
            </IconButton>
        </Fragment>
    );
}
