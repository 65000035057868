import React, { Fragment, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { useModals } from "../../../../providers/ModalsProvider";

import { formatCurrency, getSinglarOrPluralWord } from "../../../../../../../utilities/helpers";
import { processOrder } from "../../../../../../../utilities/api";

import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { Spinner } from "../../../../../../LoadingContainer/Spinner";
import { InfoIcon } from "../../../../../../InfoIcon";

import "./checkoutBottom.scss";

// This is almost identical to frontend/src/components/PopupCard/PopupCard.js in terms of jsx
// but pretty much identical to TotalCard in terms of functions and state
export default function CheckoutBottom({
    setStatus,
    intentId,
    paymentDeclined,
    cart,
}) {
    const { openModal } = useModals();

    const [isPurchasing, setIsPurchasing] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    // if (cart.listing) {
    //   return;
    //   let listing = cart?.listing;
    //   ticketPrice = listing?.pricing?.ticketCost;
    //   ticketCount = listing?.quantity;
    //   ticketFee = listing?.pricing?.serviceFees;
    //   facilityFee = listing?.pricing?.ticketFacilityFee;
    //   processingFee = listing?.pricing?.paymentProcessingFee * listing?.quantity;
    //   totalDue = listing?.pricing?.listingTotalWithFees;
    //   tax = listing?.pricing?.taxPerTicket * listing?.quantity;
    //   times = ticketCount;
    // }

    const completePurchase = () => {
        setIsPurchasing(true);
        let data = {
            cart: cart,
            paymentIntentId: intentId,
        };

        processOrder(data)
            .then((res) => {
                console.log("processOrder res: ", res.data);
                sessionStorage.setItem("order", JSON.stringify(res.data));
                sendPayment();
            })
            .catch((err) => {
                console.error(err);
                setIsPurchasing(false);
                // setHasError(true);
                // setErrorShow({
                //   error: true,
                //   msg: err.response?.data?.error?.message,
                //   remaining: err.response?.data?.error?.details?.ticketsRemaining,
                // });
            });
    };

    const sendPayment = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "",
            },
            redirect: "if_required",
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setIsPurchasing(false);
            paymentDeclined(error.message);
            console.warn(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            setStatus("successful");
        }
    };

    return (
        <div className='checkout-bottom'>
            <Stack gap={1}>
                <Stack direction='horizontal' gap={2}>
                    <h6 className='fs-md mb-0'>{formatCurrency(cart?.total)}</h6>
                    <Button
                        variant='default'
                        className='p-0 m-0'
                        onClick={() =>
                            openModal("Ticket Information", cart)
                        }
                    >
                        <InfoIcon />
                    </Button>
                </Stack>
                <span className='caption'>
                    Total for {cart?.tickets.length}{" "}
                    {getSinglarOrPluralWord(cart?.tickets.length)}
                </span>
            </Stack>

            {/* Wrap the 2 buttons so the space-between works */}
            <div>
                <div id='payment-request-button'>
                    {/* Need to rethink how payment element and totalCard manage state */}
                    {/* after payment has been entered  */}
                    <Button
                        id='payment-btn'
                        variant='primary'
                        size='lg'
                        disabled={isPurchasing}
                        className='icon-button w-100 d-none mt-0'
                        onClick={completePurchase}
                    >
                        {isPurchasing ? (
                            <Fragment>
                                <Spinner />
                                <span>Purchasing...</span>
                            </Fragment>
                        ) : (
                            <span>Submit Payment</span>
                        )}
                    </Button>
                </div>

                <div id='payment-request-button'>
                    {/* Need to rethink how payment element and totalCard manage state */}
                    {/* before payment has been entered */}
                    <Button
                        id='payment-btn-disabled'
                        disabled
                        variant='primary'
                        size='lg'
                        className='icon-button w-100 mt-0'
                    >
                        <span>Submit Payment</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}
