import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";

import UserContext from "./context/User/user";
import LoadingContext from "./context/Loading/Loading";
import AuthService from "./utilities/services/auth.service";

import {
    checkUrl, fullHeightContainer,
    removeFullHeightContainer
} from "./utilities/helpers";

import Router from "./Router";
import ConditionalContainerNav from "./ConditionalContainerNav";

function App() {
    const user = AuthService.getUser();

    const location = useLocation();

    const setLastKnown = AuthService.setLastKnown;

    const [authenticated, setAuthenticated] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // control full height pages
    useLayoutEffect(() => {
        if (checkUrl(location.pathname)) {
            ["html", "body"].forEach((el) =>
                document.querySelector(el).classList.add("full-height")
            );
            // add full height container 
            const el = document.querySelector("#main-container");
            fullHeightContainer(el);
        }

        return () => {
            ["html", "body"].forEach((el) =>
                document.querySelector(el).classList.remove("full-height")
            );
            // remove full height container 
            const el = document.querySelector("#main-container");
            removeFullHeightContainer(el);
        };
    }, [location.pathname]);

    useEffect(() => {
        let pathName = location.pathname;
        if (pathName !== "/checkout" && pathName !== "/login") {
            setLastKnown(pathName);
        }
    }, [location]);

    const showLoading = () => {
        setIsLoading(true);
    };

    const hideLoading = () => {
        setIsLoading(false);
    };

    return (
        <HelmetProvider>
            <Fragment>
                <LoadingContext.Provider
                    value={{ isLoading, showLoading, hideLoading }}
                >
                    <UserContext.Provider
                        value={{ authenticated, setAuthenticated, user }}
                    >
                        <ConditionalContainerNav>
                            <Router />
                        </ConditionalContainerNav>
                    </UserContext.Provider>
                </LoadingContext.Provider>
            </Fragment>
        </HelmetProvider>
    );
}

export default App;
