import React from 'react';
import { Link } from 'react-router-dom';

import { formatShortDate, formatDateTime, getTimezoneDate, timezones, getUrl } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';

import placeholder from '../../../assets/placeholder.png'

import './searchItem.scss';

export default function SearchItem({ event }) {

    let timezone = timezones(event?.venue?.timezone)

    return (
        <li className="search-item text-left">
            <Link to={`${getUrl(`/e/${event?.seoUrl}/${event?.shortCode}`, event?.seatmap?.ga_only)}`} className="d-flex gap-3">
                <img
                    width="48"
                    height="48"
                    className="event-image thumbnail"
                    alt={event?.name}
                    src={event?.image?.url || placeholder}
                />
                <Stack className="details-info">
                    <p className="details-info-event-name">{event?.name}</p>
                    <span className="small text-muted text-left">
                        {formatShortDate(getTimezoneDate(event?.start, timezone), 'dateOnlyWithDay')} {event?.display_start_time && (<span> @ {formatDateTime(getTimezoneDate(event?.start, timezone), 'timeOnly')} </span>)} | {event?.venue.name}
                    </span>
                </Stack>
            </Link>
        </li>
    );
}
