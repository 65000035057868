import React, { Fragment } from 'react';

import { formatDateTime, getStartDateFormatter, formatAddress, getTimezoneDate, timezones } from '../../../utilities/helpers'

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';

export default function DetailsModal({ order, guestList, ticket }) {

    const obj = order || guestList;

    let timezone = timezones(obj?.event?.venue?.timezone)

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title as="h4">Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body-heading--with-border d-flex d-flex-column pb-2 mb-0 details">
                    <h4 className="modal-body-heading-title mb-2">{obj?.event?.name}</h4>
                    <Stack as="ul" gap={1}>
                        <li><span>{formatDateTime(getTimezoneDate(obj?.event?.start, timezone), getStartDateFormatter(obj?.event))}</span></li>
                        <li>Doors Open at {formatDateTime(getTimezoneDate(obj?.event?.doorsOpen, timezone), 'timeOnly')}</li>
                    </Stack>
                </div>
                <ListGroup as="ul" variant="flush">
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">{ticket?.generalAdmission ? 'Ticket type' : 'Seat Location'}</p>
                        {ticket?.generalAdmission ? (
                            <span>General Admission</span>
                        ) : (
                            <Stack direction='horizontal' gap={4}>
                                <Stack direction='horizontal' gap={2}>
                                    <span className="normal normal-bold">Sec</span>
                                    <span>{ticket?.sectionNumber}</span>
                                </Stack>
                                <Stack direction='horizontal' gap={2}>
                                    <span className="normal normal-bold">Row</span>
                                    <span>{ticket?.rowNumber}</span>
                                </Stack>
                                <Stack direction='horizontal' gap={2}>
                                    <span className="normal normal-bold">Seat</span>
                                    <span>{ticket?.seatNumber}</span>
                                </Stack>
                            </Stack>
                        )}
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">Location</p>
                        <ul>
                            <li>{obj?.event?.venue?.name}</li>
                            <li>{formatAddress(obj?.event?.venue)}</li>
                        </ul>
                    </ListGroup.Item>
                    {obj?.orderId && (
                        <ListGroup.Item as="li" className="list">
                            <p className="heading">Order Number</p>
                            <span>{obj?.orderId}</span>
                        </ListGroup.Item>
                    )}
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">Purchase Date</p>
                        <span>{formatDateTime(getTimezoneDate((obj?.processedAt || obj?.createdAt), timezone))}</span>
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
        </Fragment>
    );
}
