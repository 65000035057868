import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer'

import Lottie from "lottie-react"

import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'

export default function FlipCard({ toggleOverlay, toggleModal, setModalContent, showModal, cardId, obj, animation, mobileAnimation, size = 'xxl', id, variant, textVariant, highlightVariant, textAlign, titleSize, subtitleSize }) {

    const [showBack, setShowBack] = useState(false);
    const windowWidth = window.innerWidth;

    // This is for the card to appear
    const [refOne, oneInView] = useInView({
        triggerOnce: true,
    });

    // This is to clear overlay / flip card on scroll
    const [refTwo, twoInView] = useInView({
        // 0.4 is about perfect for cleaning up the overlay on scroll
        threshold: 0.4
    });

    // This is for the lottie to play
    const [refThree, threeInView] = useInView({
        triggerOnce: true
    });

    // This is for the text appear
    const [refFour, fourInView] = useInView({
        triggerOnce: true
    });

    const [splitTitle, setSplitTitle] = useState('')

    // This is to control playing of the lottie
    const lottieRef = useRef()

    const modalFlipToggle = () => {
        const deviceWidth = window.innerWidth;
        // Flip card if over 569px
        if (deviceWidth > 569) { // Modal max width 
            // If modal is still open, close
            if (showModal) {
                toggleModal();
            } else {
                handleClick();
            }
            // Show modal otherwise
        } else {
            // If card is still open, close
            if (showBack) {
                setShowBack(false);
                // -1 means overlay is not visible
                toggleOverlay(-1);
            } else {
                setModalContent({ title: obj?.back.title, text: obj?.back.text })
                toggleModal();
            }
        }
    }

    const handleClick = () => {
        if (showBack) {
            setShowBack(false);
            // -1 means overlay is not visible
            toggleOverlay(-1);
        } else {
            setShowBack(true);
            toggleOverlay(cardId);
        }
    }

    useEffect(() => {
        if (obj?.front?.highlightText) {
            splitString(obj?.front.title, obj?.front?.highlightText)
        }
    }, [obj])

    useEffect(() => {
        if (!twoInView && showBack) {
            setShowBack(!showBack);
            toggleOverlay(-1);
        }
    }, [twoInView])

    // Play animation when intersecting
    useEffect(() => {
        if (animation && threeInView) {
            const timeout = setTimeout(() => {
                lottieRef.current.play();
            }, 250);
            return () => clearTimeout(timeout);
        }
    }, [threeInView])

    const splitString = (text, highlightText) => {

        // Split string on highlight terms
        let arr = [];
        const index = text.indexOf(highlightText[0])
        const first = text.substring(0, index)
        arr.push(first)
        const index1 = text.indexOf(highlightText[1])
        const second = text.substring(index, index + highlightText[0].length + 1)
        arr.push(second)
        const third = text.substring(index + highlightText[0].length + 1, index1)
        arr.push(third)
        const fourth = text.substring(index1, index1 + highlightText[1].length + 1)
        arr.push(fourth)
        const fifth = text.substring(index1 + highlightText[1].length + 1)
        arr.push(fifth)

        setSplitTitle(arr.filter(n => n))
    }

    const getAnimation = () => {
        return (windowWidth < 768 && mobileAnimation) ? mobileAnimation : animation
    }

    // flip cards on x axis 
    {/* cardId 0 & 3 & 6 & 11 make cards flip on the X axis, beware */ }
    const isFlipX = (id) => {
        return (id == 0 || id == 3 || id == 6 || id == 11)
    }

    return (
        <div ref={refOne} className={`flip-card-outer flip-card-outer-${size} ${oneInView ? 'card_appear' : ''}`} onClick={modalFlipToggle} id={id}>
            <Card className={`flip-card-inner  ${showBack ? 'showBack' : ''}`}>
                <Card.Body ref={refTwo} className={`inner-card ${isFlipX(cardId) ? 'frontX' : 'frontY'} card-${size} ${variant ? `card--${variant}` : ''} ${textAlign ? `justify-content-end justify-content-lg-${textAlign}` : ''} `}>
                    {animation && (
                        <div ref={refThree} className="lottie">
                            <Lottie lottieRef={lottieRef} autoplay={false} animationData={getAnimation()} loop={false} />
                        </div>
                    )}
                    <div ref={refFour} className={`card-content ${fourInView ? 'text_appear' : ''}`}>
                        {obj?.front.label && (
                            <Badge bg="warning" text="dark" className='badge--clock normal'>{obj?.front.label}</Badge>
                        )}
                        {obj?.front?.highlightText ? (

                            <Card.Title as="h5">{splitTitle[0]} <span className={`text-${highlightVariant} `}>{splitTitle[1]}</span> {splitTitle[2]}<span className={`text-${highlightVariant} `}>{splitTitle[3]}</span> {splitTitle[4]}</Card.Title>
                        ) : (
                            <Card.Title as="h5" className={`${titleSize ? `card-title-${titleSize}` : ''}`}>{obj?.front?.title}</Card.Title>
                        )}

                        {obj?.front.subtitle && (
                            <Card.Subtitle as="h4" className={`${subtitleSize ? `card-subtitle-${subtitleSize}` : ''} ${textVariant ? `text-${textVariant}` : ''} `}>{obj?.front.subtitle}</Card.Subtitle>
                        )}
                    </div>
                </Card.Body>
                <Card.Body className={`inner-card ${isFlipX(cardId) ? 'backX' : 'backY'} card-${size} ${variant ? `card--${variant}` : ''} ${textAlign ? `justify-content-start justify-content-lg-${textAlign}` : ''} `}>
                    <Card.Title as="h5">{obj?.back.title}</Card.Title>
                    <Card.Text as="p">{obj?.back.text}</Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}
