import React from "react";

import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Dropdown from "react-bootstrap/Dropdown";

import "./priceSort.scss";
import { useTickets } from "../../NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider";

export default function PriceSort({
  btnStyle,
  sm,
  style,
  handleChange,
  dropdown,
}) {
  const { sort } = useTickets();

  if (dropdown) {
    return (
      <ul className={`mb-1 p-1 actions-bar ${style || ""}`}>
        <Dropdown.Item
          eventKey={"price"}
          value={"price"}
          active={sort === "price"}
          className={``}
        >
          Price
        </Dropdown.Item>
        <Dropdown.Item
          eventKey={"bestseat"}
          value={"bestseat"}
          active={sort === "bestseat"}
          className={``}
        >
          Best seat
        </Dropdown.Item>
      </ul>
    );
  }
  return (
    <ToggleButtonGroup
      type='radio'
      name='btn-rad'
      className={`mb-1 p-1 actions-bar ${style || ""}`}
      onChange={handleChange}
      value={sort}
    >
      <ToggleButton
        id='tbg-btn-rad-1'
        value={"price"}
        className={`mt-0 ${btnStyle || ""}`}
        variant='default'
      >
        Price
      </ToggleButton>
      <ToggleButton
        id='tbg-btn-rad-2'
        value={"bestseat"}
        className={`mt-0 ${btnStyle || ""} ${sm || ""}`}
        variant='default'
      >
        Best Seat
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
