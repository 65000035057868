import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Event } from "../../components/Event";
import { TicketSelectionPanel } from "./TicketSelectionPanel";
import { useModals } from "../providers/ModalsProvider/ModalsProvider";

import { useTickets } from "../providers/TicketsProvider/TicketsProvider";
import "../../purchaseFlow.scss";
import "./newSeatedPurchaseContainer.scss";

export default function NewSeatedPurchaseContainer() {
    const navigate = useNavigate();
    const { event, isScheduled, scheduledTime } = useTickets();
    const { openCBModal } = useModals();

    // Hide intercom widget on this page
    // This does hide it until you refresh the page / turn it back on again
    useEffect(() => {
        if (window.Intercom) {
            window.Intercom("update", {
                hide_default_launcher: true,
            });
        }
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div
            className={` max-h-screen purchaseflow-gradient`}
        >
            <div className='d-flex-column nstc-ss'>
                <div className="pxccc">
                    <Event
                        event={event}
                        onClick={handleGoBack}
                        openModal={openCBModal}
                        showBack={true}
                    />
                </div>
                <TicketSelectionPanel
                    isScheduled={isScheduled}
                    scheduledTime={scheduledTime}
                />
            </div>
        </div>
    );
}
